<template>
    <div>
      <section>
        <div class="section-inner">
          <div class="section-cont">
            <h2>관리</h2>
            <p class="site-path">
              <span>Home</span>&nbsp;&gt;&nbsp;
              <span> - </span>&nbsp;&gt;&nbsp;
              <span>관리</span>
            </p>
          </div>

          <app-footer/>
        </div>
      </section>
    </div>
</template>

<script>
import AppFooter from '../../AppFooter.vue';

export default {
  components: { AppFooter },
  name: 'GiftCardManagerShow',
}
</script>