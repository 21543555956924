<template>
  <div>
    <section class="find-store-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>매장찾기 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>매장찾기 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>

            <tr>
              <th scope="row">매장명</th>
              <td>동구청점</td>
            </tr>
            <tr>
              <th scope="row">상태</th>
              <td>운영중</td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td>경기도 수원시 영통구 영통동 995-2번지 미네시티플라자 1층</td>
            </tr>
            <tr>
              <th scope="row">연락처</th>
              <td>010-0000-0000</td>
            </tr>
            <tr>
              <th scope="row">블로그 URL</th>
              <td>http://blog.naver.com/davich7868</td>
            </tr>
            <tr>
              <th scope="row">카카오채널 URL</th>
              <td>https://pf.kakao.com/_exaxexoM/friend</td>
            </tr>
            <tr>
              <th scope="row">제휴업체 정보</th>
              <td>제휴업체 정보 표시</td>
            </tr>
            <tr>
              <th scope="row">와이파이</th>
              <td>Y</td>
            </tr>
            <tr>
              <th scope="row">주차장</th>
              <td>Y</td>
            </tr>
            <tr>
              <th scope="row">렌즈샵</th>
              <td>N</td>
            </tr>
            <tr>
              <th scope="row">보청기</th>
              <td>N</td>
            </tr>
            <tr>
              <th scope="row">누진 피팅센터</th>
              <td>Y</td>
            </tr>
            <tr>
              <th scope="row">위치정보</th>
              <td>
                한티역 2번 출구에서 은마사거리 방향으로 300m직진하시면
                강대마이맥 (구)베스티안 병원 옆건물에 있습니다.
              </td>
            </tr>
            <tr>
              <th scope="row">매장 이미지</th>
              <td class="store-img-cell">
                <p>
                  <label for="store-url">URL</label>
                  <input type="text" name="store-url" id="store-url" value="" />
                </p>
                <p>
                  <label for="store-url">URL</label>
                  <input type="text" name="store-url" id="store-url" value="" />
                </p>
                <p>
                  <label for="store-url">URL</label>
                  <input type="text" name="store-url" id="store-url" value="" />
                </p>
                <p>
                  <label for="store-url">URL</label>
                  <input type="text" name="store-url" id="store-url" value="" />
                </p>
                <p>
                  <label for="store-url">URL</label>
                  <input type="text" name="store-url" id="store-url" value="" />
                </p>
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'FindStoreList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "FindStoreEdit",
  data() {
    return {

    }; 
  },
  created() {
    console.log('created item => ', this.item); 
  },


};

</script>


<style lang="scss" scoped>
.ly-edit .section-inner .section-cont table td input[type="text"] {
  height: 44px;
}
.ly-edit .section-inner .section-cont {
  table {
    .store-img-cell {
      padding: 0;
      p {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dedede;
        &:last-child {
          border-bottom: none;
        }
        label {
          display: inline-block;
          height: 59px;
          line-height: 59px;
          width: 94px;
          background-color: #f9f9f9;
          text-align: center;
          border-right: 1px solid #dedede;
        }
        input {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
