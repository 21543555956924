<template>
  <div>
    <section class="find-store-show ly-show">
      <div class="section-inner">
        <div class="section-cont">
          <h2>매장찾기 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>매장찾기 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>

            <tr>
              <th scope="row">매장명</th>
              <td>{{ item.str_name }}</td>
            </tr>
            <tr>
              <th scope="row">상태</th>
              <td>{{ item.end_ind == '1' ? '운영중': '폐점'}}</td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td>{{item.addr1 + ' ' + item.addr2 }}</td>
            </tr>
            <tr>
              <th scope="row">연락처</th>
              <td>{{item.tel_no}}</td>
            </tr>
            <tr>
              <th scope="row">블로그 URL</th>
              <td>{{item.blog_add}}</td>
            </tr>
            <tr>
              <th scope="row">카카오채널 URL</th>
              <td>{{ item.kakao_add }}</td>
            </tr>
            <tr>
              <th scope="row">제휴업체 정보</th>
              <td> 
              <p v-for="company in item.store_affiliate_masters" v-bind:key="company.seq_no">
                  <span>{{company.ven_name }}</span>
                  <em class="store-url"> {{company.cont + ' : ' + company.dc_rate +'%'  +  ' 기간 : ' + event_period(company.s_date,company.e_date) }}</em>
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">와이파이</th>
              <td> {{ item.wifi_yn == null ? 'N': item.wifi_yn }} </td>
            </tr>
            <tr>
              <th scope="row">주차장</th>
              <td>{{ item.parking_yn == null ? 'N' : item.parking_yn }} </td>
            </tr>
            <!-- <tr>
              <th scope="row">렌즈샵</th>
              <td>N</td>
            </tr> -->
            <tr>
              <th scope="row">보청기</th>
              <td>{{ item.hearing_aid == null ? "N": item.hearing_aid }}</td>
            </tr>
            <!-- <tr>
              <th scope="row">누진 피팅센터</th>
              <td>Y</td>
            </tr> -->
            <tr>
              <th scope="row">위치정보</th>
              <td>
                {{ item.location_information }}
              </td>
            </tr>
            <tr>
              <th scope="row">위치좌표값</th>
              <td>{{ item.latitude }} , {{ item.longitude }}</td>
            </tr>
            
            <tr>
              <th scope="row">매장 이미지</th>
              <td class="store-img-cell">

                <p v-for="storeimage in item.store_image_masters" v-bind:key="storeimage.seq">
                  <span>URL</span>
                  <em class="store-url">
                    {{storeimage.store_photo_url}}</em>
                </p>

              </td>
            </tr>

          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'FindStoreList' }" class="btn-white"
            >목록</router-link
          >
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "FindStoreShow",
  data() {
    return {

    }; 
  },
  created() {
    console.log('created item => ', this.item); 
  },
  methods: {
    event_period: function( sdatestr , edatestr )  {

      var sy = sdatestr.substr(0,4),
        sm = sdatestr.substr(4,2) - 1,
        sd = sdatestr.substr(6,2);
      var sdate =new Date(sy,sm,sd) ;
      
      if( edatestr != null && edatestr != undefined ) { 
        var ey = edatestr.substr(0,4),
          em = edatestr.substr(4,2) - 1,
          ed = edatestr.substr(6,2);
          var edate =new Date(ey,em,ed) ;
        return '' + this.$moment(sdate).format("YYYY.MM.DD") + " ~ " +  this.$moment(edate).format("YYYY.MM.DD") ;
      }
      else {
        return '' + this.$moment(sdate).format("YYYY.MM.DD")  + " ~ "  ;
      }      
    }
  },

};
</script>

<style lang="scss" scoped>
.ly-show .section-inner .section-cont table td input[type="text"] {
  height: 44px;
}
.ly-show .section-inner .section-cont {
  table {
    .store-img-cell {
      padding: 0;
      p {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dedede;
        &:last-child {
          border-bottom: none;
        }
        span {
          display: inline-block;
          height: 59px;
          line-height: 59px;
          width: 94px;
          background-color: #f9f9f9;
          text-align: center;
          border-right: 1px solid #dedede;
        }
        .store-url {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
