<template>
  <div>
    <section class="customer-voice-post-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>고객의 소리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>고객의 소리</span>
            <!-- /.site-path -->
          </p>
          <ul class="tab-menu store-tab-menu">
            <li>
              <button
                v-bind:class="{ checked: content_type == 0 }"
                @click="onButtonContentType(0)"
              >
                전체
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 1 }"
                @click="onButtonContentType(1)"
              >
                칭찬 접수
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 2 }"
                @click="onButtonContentType(2)"
              >
                불만 접수
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 3 }"
                @click="onButtonContentType(3)"
              >
                단순 문의
              </button>
            </li>
          </ul>
          <!-- /.tab menu-->
          <p class="total-post">
            전체 게시물 <span> {{ getDataTotalCount }} </span>개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" class="search-top-175" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
              <col class="col7" />
              <col class="col8" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">구분</th>
                <th scope="col">성함</th>
                <th scope="col">연락처</th>
                <th scope="col">등록일시</th>
                <th scope="col">상태</th>
                <th scope="col">확인일시</th>
                <th scope="col">답변</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">고객의 소리 체크</label>
                  <input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ getCategoryName(item.category_id) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ maskingFuncName(item.name) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ maskingFuncPhone(item.mobile_no) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD HH:mm") }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    <span class="confirm" v-if="item.status == 1">확인</span>
                    <span class="unconfirm" v-else>미확인</span>
                  </router-link>
                </td>
                <td>
                  <!--------------------------------------추가된 부분-------------------------------------->
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    <span v-if="item.status == 1">{{
                      item.confirm_dttm != null
                        ? $moment(item.confirm_dttm).format("YYYY.MM.DD HH:mm")
                        : "-"
                    }}</span>
                    <span v-else>-</span>
                  </router-link>
                  <!--------------------------------------추가된 부분 end-------------------------------------->
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'CustomerVoicePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    <span
                      class="confirm"
                      v-if="item.customer_voice_answers.length > 0"
                      >완료</span
                    >
                    <span class="unconfirm" v-else>미완료</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :initPage="selectedPage"
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
              :key="selectedPage"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="@/assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
          </div>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "CustomerVoicePostList",
  data() {
    return {
      content_type: 0,
      data_list: [],
      data_total_count: 0,
      selectedPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      getCustomerVoicePostContentType:
        "support/getCustomerVoicePostContentType",
      getCustomerVoicePostSelectedPage:
        "support/getCustomerVoicePostSelectedPage",
    }),
    getData: function () {
      return this.data_list;
    },
    getDataTotalCount: function () {
      return this.data_total_count;
    },
  },
  created() {
    this.selectedPage = this.getCustomerVoicePostSelectedPage;
    this.content_type = this.getCustomerVoicePostContentType;
    console.log(
      "created page ",
      this.selectedPage,
      " content_type => ",
      this.content_type
    );
    const payload = {
      page: this.selectedPage,
      size: this.PAGE_SIZE,
    };
    if (this.content_type != 0) {
      payload.category_id = this.content_type;
    }
    this.loadData(payload);
  },
  methods: {
    ...mapMutations({
      setCustomerVoicePostSelectedPage: "support/SET_CUSTOMERVOICE_POST_PAGE",
      setCustomerVoicePostContentType:
        "support/SET_CUSTOMERVOICE_POST_CONTENT_TYPE",
    }),
    getCategoryName(category_id) {
      if (category_id == 1) return "칭찬 접수";
      else if (category_id == 2) return "불만 접수";
      else if (category_id == 3) return "단순 접수";
      else return "-";
    },
    loadData(payload) {
      this.$store
        .dispatch("support/req_customervoice_list", payload)
        .then((result) => {
          console.log("req_customervoice_list result => ", result);
          this.data_list = result.data;
          this.data_total_count = result.total_count;
          this.selectedPage = payload.page;
          this.setCustomerVoicePostSelectedPage({
            customervoice_post_page: payload.page,
          });

          if (payload.category_id == undefined || payload.category_id == null) {
            this.setCustomerVoicePostContentType({
              customervoice_post_content_type: 0,
            });
          } else {
            this.setCustomerVoicePostContentType({
              customervoice_post_content_type: payload.category_id,
            });
          }
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
    onSearch(text) {
      this.content_type = 0;
      this.searchtext = text;
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      const payload = {
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      };
      if (this.getCustomerVoicePostContentType != 0) {
        payload.category_id = this.getCustomerVoicePostContentType;
      }
      this.loadData(payload);
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store
          .dispatch("support/req_remove_customervoice_list", {
            idarr: this.checkedValues,
          })
          .then((result) => {
            this.checkedValues = [];
            this.data_list = result.data;
            this.data_total_count = result.total_count;
          });
      }
    },
    onButtonContentType(type) {
      const prevContentType = this.getCustomerVoicePostContentType;
      this.setCustomerVoicePostContentType({
        customervoice_post_content_type: type,
      });

      console.log("직전 타입 : ", prevContentType, " 이번 선택 타입 : ", type);

      if (prevContentType !== type) {
        // 카테고리를 변경한 경우
        this.content_type = type;
        this.setCustomerVoicePostContentType({
          customervoice_post_content_type: type,
        });

        this.selectedPage = 1;
        const payload = {
          page: this.selectedPage,
          size: this.PAGE_SIZE,
        };
        if (type != 0) {
          payload.category_id = this.content_type;
        }
        this.loadData(payload);
      }
    },
  },
};
</script>

<style scoped>
.col1 {
  width: 64px;
}
.col2 {
  width: 90px;
}
.col3 {
  width: 230px;
}

.unconfirm {
  color: #0056aa;
}
.confirm {
  color: #cccccc;
}
</style>
