<template>
  <div>
    <section class="gift-card-manager-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>지류 상품권 구매 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>지류 상품권 구매 담당자 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="gift-card-manager-name">담당자</label>
              </th>
              <td>
                <input
                  type="text"
                  id="gift-card-manager-name"
                  name="gift-card-manager-name"
                  v-model="name"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="gift-card-manager-num">연락처</label>
              </th>
              <td>
                <input
                  type="text"
                  id="gift-card-manager-num"
                  name="gift-card-manager-num"
                  v-model="mobile_no"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="gift-card-manager-email">이메일</label>
              </th>
              <td>
                <input
                  type="text"
                  id="gift-card-manager-email"
                  name="gift-card-manager-email"
                  v-model="email"
                />
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'GiftCardManagerList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import mananger_handle_mixin from '../../../mixins/managerHandle_mixin'; 

export default {
  components: { AppFooter },
  name: "GiftCardManagerCreate",
  mixins: [mananger_handle_mixin],
  data() {
    return {
      taskpart: 'giftcard',
    };
  },
  methods: {
    onClickCreate() {
      if( this.name == null  || this.name === undefined || this.name.length <= 0 ) return ;  
      if( this.mobile_no == null || this.mobile_no === undefined || this.mobile_no.length <= 0 ) return ; 
      if( this.email == null  || this.email === undefined || this.email.length <= 0 ) return ; 

      this.reqCreateManager( {
          taskpart: this.taskpart,
          name: this.name,
          mobile_no: this.mobile_no,
          email: this.email ,
        } , "GiftCardManagerList" ) ; 
    },
    
  },
};
</script>
