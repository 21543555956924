<template>
  <div>
    <section class="customer-voice-manager-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>고객의 소리 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>고객의 소리 담당자 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" /> 
            </colgroup>
            <tr>
              <th scope="row">
                <label for="customer-voice-manager-name">담당자</label>
              </th>
              <td>
                <input
                  type="text"
                  id="customer-voice-manager-name"
                  name="customer-voice-manager-name"
                  value="담당자 명이 표시됩니다"
                  v-model="name"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="customer-voice-manager-num">연락처</label>
              </th>
              <td>
                <input
                  type="text"
                  id="customer-voice-manager-num"
                  name="customer-voice-manager-num"
                  p
                  value="연락처가 표시됩니다"
                  v-model="mobile_no"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="customer-voice-manager-email">이메일</label>
              </th>
              <td>
                <input
                  type="text"
                  id="customer-voice-manager-email"
                  name="customer-voice-manager-email"
                  value="이메일이 표시됩니다"
                  v-model="email"
                />
              </td>
            </tr>
          </table>
          <!-- /.table-->

          <router-link
            :to="{ name: 'CustomerVoiceManagerList' }"
            class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import mananger_handle_mixin from '../../../mixins/managerHandle_mixin'; 

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "CustomerVoiceManagerEdit",
  mixins: [mananger_handle_mixin],
  data() {
    return {
      taskpart: "customervoice"
    };
  },
  created() {
    this.name = this.item.name ; 
    this.mobile_no = this.item.mobile_no ; 
    this.email = this.item.email ; 
  },
  methods: {
    onSubmitModify() {
      this.reqUpdateManager(    
        {
          taskpart: this.taskpart ,
          id: this.item.id, 
          name: this.name ,
          mobile_no : this.mobile_no,
          email: this.email 
        }
      );      
    },
  },
};
</script>
