<template>
  <div>
    <section class="faq-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>자주 하는 질문 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>자주 하는 질문 관리</span>
          </p>
          <!-- /.site-path -->
          <ul class="tab-menu store-tab-menu">
            <li>
              <button
                v-bind:class="{ checked: content_type == 0 }"
                @click="onButtonContentType(0)"
              >
                전체
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 1 }"
                @click="onButtonContentType(1)"
              >
                개인정보
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 2 }"
                @click="onButtonContentType(2)"
              >
                멤버십
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 3 }"
                @click="onButtonContentType(3)"
              >
                상품
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 4 }"
                @click="onButtonContentType(4)"
              >
                매장
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: content_type == 5 }"
                @click="onButtonContentType(5)"
              >
                일반
              </button>
            </li>
          </ul>
          <!-- /.tab menu-->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" class="search-top-175" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">구분</th>
                <th scope="col">질문</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden"
                    >고객의 소리 담당자 관리 체크</label
                  >
                  <input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'FaqEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'FaqEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ getCategoryName(item.category_id) }}
                  </router-link>
                </td>
                <td class="left">
                  <router-link
                    :to="{
                      name: 'FaqEdit',
                      params: { id: item.id, item: item },
                    }"
                    class="of-hidden"
                  >
                    <p v-html="item.question" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="@/assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link :to="{ name: 'FaqCreate' }" tag="a" class="btn-blue"
              >등록
            </router-link>
          </div>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "FaqList",
  data() {
    return {
      content_type: 0,
      data_list: [],
      data_total_count: 0,
    };
  },
  computed: {
    getData: function () {
      return this.data_list;
    },
    getDataTotalCount: function () {
      return this.data_total_count;
    },
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    });
  },
  methods: {
    getCategoryName(category_id) {
      if (category_id == 1) return "개인정보";
      else if (category_id == 2) return "멤버십";
      else if (category_id == 3) return "상품";
      else if (category_id == 4) return "매장";
      else if (category_id == 5) return "일반";
      else return "-";
    },
    loadData(payload) {
      this.$store
        .dispatch("support/req_faq_list", payload)
        .then((result) => {
          console.log("reqFaqList result => ", result);
          this.data_list = result.data;
          this.data_total_count = result.total_count;
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },

    onSearch(text) {
      this.content_type = 0;
      this.searchtext = text;
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        this.$store
          .dispatch("support/req_remove_faq_list", {
            idarr: this.checkedValues,
          })
          .then((result) => {
            this.checkedValues = [];
            this.data_list = result.data;
            this.data_total_count = result.total_count;
          });
      }
    },
    onButtonContentType(type) {
      this.content_type = type;
      this.loadData({
        category_id: this.content_type,
        page: 1,
        size: this.PAGE_SIZE,
      });
    },
  },
};
</script>
<style scoped>
.col1 {
  width: 64px;
}
.col2 {
  width: 130px;
}
.col3 {
  width: 300px;
}
</style>
