<template>
  <div>
    <section class="faq-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>자주 하는 질문 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>자주 하는 질문 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="category">구분</label>
              </th>
              <td>
                <select
                  name="category"
                  id="category"
                  v-model="selectedCategory"
                >
                  <option
                    v-for="cate in categoryList"
                    v-bind:key="cate.category_id"
                    :value="cate"
                  >
                    {{ cate.name }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="title">질문</label>
              </th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>
              <th scope="row">답변</th>
              <td>
                <ckeditor
                  :editor="editor2"
                  v-model="editorData2"
                  :config="editorConfig2"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>
              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]"></file-select>
                  </div>
                </div>

                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'FaqList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
// import axios from "@/api/axios-auth";

export default {
  components: { AppFooter, FileSelect },
  name: "FaqCreate",
  data() {
    return {
      selectedCategory: null,
      categoryList: [
        { category_id: 1, name: "개인정보" },
        { category_id: 2, name: "멤버십" },
        { category_id: 3, name: "상품" },
        { category_id: 4, name: "매장" },
        { category_id: 5, name: "일반" },
      ],

      editor: ClassicEditor,
      files: [null, null, null, null, null],
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },

      editor2: ClassicEditor,
      editorData2: "",
      editorConfig2: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  created() {
    this.selectedCategory = this.categoryList[0];
  },
  methods: {
    onClickCreate() {
      let formData = new FormData();

      formData.append("category_id", this.selectedCategory.category_id);
      formData.append("question", this.editorData);
      formData.append("answer", this.editorData2);

      var count = 0;
      for (let attfile of this.files) {
        if (attfile != null && attfile != undefined) {
          var fieldname = "files[" + count + "]";
          formData.append(fieldname, attfile);
        }
        count++;
      }

      this.$store
        .dispatch("support/req_create_faq", formData)
        .then((result) => {
          console.log("req_create_faq result => ", result);
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "FaqList" });
          });
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
  },
};
</script>
<style scoped>
select {
  width: 230px !important;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 280px !important;
}
</style>
