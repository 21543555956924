<template>
  <div>
    <section class="gift-card-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>지류 상품권 구매</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>지류 상품권 구매</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">성함</th>
                <th scope="col">연락처</th>
                <th scope="col">이메일</th>
                <th scope="col">등록일</th>
                <th scope="col">상태</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td>
                  <router-link
                    :to="{
                      name: 'GiftCardShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'GiftCardShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ maskingFuncName(item.name) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'GiftCardShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ maskingFuncPhone(item.mobile_no) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'GiftCardShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ maskingFuncEmail(item.email) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'GiftCardShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD") }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'GiftCardShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    <span v-if="item.status == 0" class="unconfirm"
                      >미완료</span
                    >
                    <span v-else class="confirm">완료</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "GiftCardList",
  data() {
    return {
      data_list: [],
      data_total_count: 0,
    };
  },
  computed: {
    getData: function () {
      return this.data_list;
    },
    getDataTotalCount: function () {
      return this.data_total_count;
    },
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    });
  },
  methods: {
    loadData(payload) {
      this.$store
        .dispatch("support/req_giftcardorder_list", payload)
        .then(result => {
          console.log("req_giftcardorder_list result => ", result);
          this.data_list = result.data;
          this.data_total_count = result.total_count;
        })
        .catch(err => {
          console.log(" error => ", err);
        });
    },
    onSearch(text) {
      this.searchtext = text;
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        this.$store
          .dispatch("support/req_remove_giftcardorder_list", {
            idarr: this.checkedValues,
          })
          .then(result => {
            this.checkedValues = [];
            this.data_list = result.data;
            this.data_total_count = result.total_count;
          });
      }
    },
  },
};
</script>
<style scoped>
.col1 {
  width: 70px;
}
.col2 {
  width: 130px;
}
.col3 {
  width: 175px;
}
.col5 {
  width: 150px;
}
.col6 {
  width: 115px;
}

.ly-list .section-inner .section-cont td a {
  padding: 14px 10px;
}
.confirm {
  color: #cccccc;
}
.unconfirm {
  color: #0056aa;
}
</style>
