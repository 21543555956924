<template>
  <div>
    <section class="gift-card-show ly-show">
      <div class="section-inner">
        <div class="section-cont">
          <h2>지류 상품권 구매</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>지류 상품권 구매</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">상품권 종류</th>
              <td class="gift-card-type">
                <span
                  >10만원권 &#58; <em>{{ item.voucher10 }}</em
                  >매</span
                >
                <span
                  >5만원권 &#58; <em>{{ item.voucher5 }}</em
                  >매</span
                >
                <span
                  >1만원권 &#58; <em>{{ item.voucher1 }}</em
                  >매</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">성함</th>
              <td>{{ item.name }}</td>
            </tr>
            <tr>
              <th scope="row">연락처</th>
              <td>{{ item.mobile_no }}</td>
            </tr>
            <tr>
              <th scope="row">이메일</th>
              <td>{{ item.email }}</td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td>{{ item.address1 + " " + item.address2 }}</td>
            </tr>
            <tr>
              <th scope="row">기타사항</th>
              <td>
                <p v-html="strReplaceReturnText(item.message)" />
              </td>
            </tr>
            <tr>
              <th scope="row">상태</th>
              <td>{{ item.status == 1 ? "완료" : "미완료" }}</td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'GiftCardList' }" class="btn-white"
            >목록</router-link
          >
          <button
            class="btn-blue btn-right complete-btn"
            v-if="this.item.status == 0"
            @click="onConfirmComplete"
          >
            완료
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "GiftCardShow",
  methods: {
    strReplaceReturnText(text) {
      let replacedText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
      return replacedText;
    },
    onConfirmComplete() {
      this.$fire({
        title: this.msg.giftcardorder_complete_confirm_title,
        text: this.msg.giftcardorder_complete_confirm_message,
        type: null,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "완료",
      })
        .then((result) => {
          console.log("confirm result =>", result);
          if (result.value) {
            // 삭제 선택 ( confrim 선택함 )
            this.onSubmitComplete();
          }
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    onSubmitComplete() {
      // 완료인 경우 버튼 노출이 되지 않음
      // 완료로 상태 변경 요청.
      const payload = {
        id: this.item.id,
        status: 1,
      };

      this.$store
        .dispatch("support/req_update_giftcardorder", payload)
        .then(() => {
          this.$alert("수정하였습니다.").then(() => {
            this.$router.push({ name: "GiftCardList" });
          });
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
    onSubmitDeleteOne() {
      console.log("onSubmitDeleteOne ==> ");

      this.$store
        .dispatch("support/req_remove_giftcardorder_list", {
          idarr: [this.item.id],
        })
        .then((result) => {
          console.log("result => ", result);
          this.$router.push({ name: "GiftCardList" });
        });
    },
  },
  created() {
    console.log("created => ", this.item);
  },
};
</script>
<style lang="scss" scoped>
.btn-icon-del {
  border-color: #707070;
}
.gift-card-type span {
  display: inline-block;
  margin-right: 38px;
  position: relative;
}
.gift-card-type span:before {
  position: absolute;
  content: "";
  right: -20px;
  top: 6px;
  width: 1px;
  height: 20px;
  background-color: #dedede;
}
.gift-card-type span:last-child:before {
  display: none;
}

.complete-btn {
  margin-right: 10px;
}
</style>
