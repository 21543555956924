<template>
  <div>
    <section class="find-store-content-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>매장찾기 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>SUPPORT</span>&nbsp;&gt;&nbsp;
            <span>매장찾기 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">매장명</th>
                <th scope="col">상태</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td>
                  <router-link
                    :to="{
                      name: 'FindStoreShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td class="left">
                  <router-link
                    :to="{
                      name: 'FindStoreShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.str_name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'FindStoreShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.end_ind == "1" ? "운영중" : "폐점" }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "FindStoreList",
  data() {
    return {
      data_list: [],
      data_total_count: 0,
    };
  },
  computed: {
    getData: function () {
      return this.data_list;
    },
    getDataTotalCount: function () {
      return this.data_total_count;
    },
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    });
  },
  methods: {
    loadData(payload) {
      this.$store
        .dispatch("support/req_store_list", payload)
        .then((result) => {
          console.log("req_store_list result => ", result);
          this.data_list = result.data;
          this.data_total_count = result.total_count;
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
    onSearch(text) {
      this.searchtext = text;
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      });
    },
    // onSubmitDelete() {
    //   console.log("onSubmitDelete ==> ", this.checkedValues);
    //   // if (this.checkedValues.length > 0) {
    //   //   // choice
    //   //   this.$store.dispatch("support/removeFindStoreList", {
    //   //     idarr: this.checkedValues,
    //   //   });
    //   // }
    // },
  },
};
</script>
<style scoped>
.col1 {
  width: 150px;
}
.col3 {
  width: 200px;
}
</style>
