<template>
  <div>
    <section class="customer-voice-post-show ly-show">
      <div class="section-inner">
        <div class="section-cont">
          <div class="customoer-voice-post-show-item">
            <h2>고객의 소리</h2>
            <p class="site-path">
              <span>Home</span>&nbsp;&gt;&nbsp;
              <span>SUPPORT</span>&nbsp;&gt;&nbsp;
              <span>고객의 소리</span>
            </p>
            <!-- /.site-path -->
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
              </colgroup>
              <tr>
                <th scope="row">구분</th>
                <td>{{ getCategoryName(this.itemdata.category_id) }}</td>
              </tr>
              <tr>
                <th scope="row">성함</th>
                <td>{{ this.itemdata.name }}</td>
              </tr>
              <tr>
                <th scope="row">연락처</th>
                <td>{{ this.itemdata.mobile_no }}</td>
              </tr>
              <tr>
                <th scope="row">이메일</th>
                <td>{{ this.itemdata.email }}</td>
              </tr>
              <tr>
                <th scope="row">내용</th>
                <td class="cont-cell">
                  <p v-html="strReplaceReturnText(this.itemdata.question)" />
                </td>
              </tr>
              <tr>
                <!----------------------------------추가된 부분---------------------------------->
                <th scope="row">첨부파일</th>

                <td class="cont-cell">
                  <!-- <a href="" class="file-download">제안서.pdf</a> [997.75MB] -->
                  <a
                    v-if="this.itemdata.attachment != null"
                    :href="this.itemdata.attachment.download_url"
                    :download="this.itemdata.attachment.originalname"
                    class="resume-download"
                    target="_blank"
                    >{{ this.itemdata.attachment.originalname }}
                  </a>
                  &nbsp;&nbsp;<span v-if="this.itemdata.attachment != null"
                    >[{{
                      formatSizeUnits(this.itemdata.attachment.size)
                    }}]</span
                  >
                  <span v-else>-</span>
                </td>

                <!----------------------------------추가된 부분 end---------------------------------->
              </tr>
              <tr>
                <th scope="row">상태</th>
                <td class="cont-cell">
                  <span v-if="this.itemdata.status == 1">확인</span>
                  <span v-else>미확인</span>
                </td>
              </tr>
            </table>
            <!-- /.table -->
            <router-link
              :to="{ name: 'CustomerVoicePostList' }"
              class="btn-white"
              >목록</router-link
            >
            <div class="btn-wrap">
              <button
                class="btn-unconfirm"
                v-if="this.itemdata.status == 1"
                @click="onUpadateStatus(0)"
              >
                미확인
              </button>
              <button class="btn-confirm" v-else @click="onUpadateStatus(1)">
                확인
              </button>
              <button
                class="btn-icon-del btn-icon-del-s btn-right"
                @click="onConfirmDeleteOne"
              >
                <img
                  src="../../../assets/image/btn-del.png"
                  alt="delete icon"
                />
                <span>삭제</span>
              </button>
            </div>
          </div>
          <!-- /.customoer-voice-post-show-item -->

          <!----------------------------------추가된 부분---------------------------------->
          <div
            class="customoer-voice-post-show-item customoer-voice-post-answer"
          >
            <h2>고객의 소리 답변 등록(이메일 발송)</h2>
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
              </colgroup>
              <tr>
                <th scope="row">답변 내용</th>
                <td>
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                    class="text-area"
                  ></ckeditor>
                </td>
              </tr>
            </table>
            <!-- /.table -->
            <div class="space-between">
              <!----------------------------------추가된 부분---------------------------------->
              <div class="attached-img-wrap">
                <div class="attached-file">
                  <p v-if="file">{{ file.name }}</p>
                  <p v-else class="file-empty"></p>
                  <file-select
                    v-model="file"
                    :spanText="'이미지 첨부'"
                    v-on:input="onChangedSignatureImage"
                  ></file-select>

                  <!-- <a href="" class="file-download"> 전자서명V1_20220825.jpg</a> -->
                  <a
                    v-if="currentSignatureImagefile != null"
                    :href="this.currentSignatureImagefile.download_url"
                    :download="this.currentSignatureImagefile.download_url"
                    class="resume-download"
                    target="_blank"
                  >
                    {{ this.currentSignatureImagefile.originalname }}</a
                  >
                </div>

                <div class="file-txt">
                  ※ 가로 최대 700px의 jpg 또는 png 파일을 업로드해주세요.
                </div>
              </div>
              <button class="btn-blue" @click="onCustomerVoiceAnsewer">
                답변 등록
              </button>
              <!----------------------------------추가된 부분 end---------------------------------->
            </div>
            <!--./btn-->
            <!-- <div class="clearfix">
              <button class="btn-register btn-right" @click="onCustomerVoiceAnsewer">답변등록</button>
            </div> -->
          </div>
          <!-- /.customoer-voice-post-show-item -->
          <div
            class="customoer-voice-post-show-item customoer-voice-post-history"
            v-if="showAnswer"
          >
            <h2>고객의 소리 답변 히스토리</h2>
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
                <col class="col3" />
              </colgroup>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">답변 내용</th>
                <th scope="col">등록일시</th>
              </tr>

              <tr v-for="item in getAnswerData" v-bind:key="item.id">
                <td>{{ item.id }}</td>
                <td>
                  <p v-html="item.answer" />
                </td>
                <td>
                  {{ $moment(item.created_dttm).format("YYYY.MM.DD HH:mm") }}
                </td>
              </tr>
            </table>
            <!-- /.table -->
          </div>
          <!-- /.customoer-voice-post-show-item -->
        </div>
        <!-- /.section-cont -->
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapGetters } from "vuex";
import FileSelect from "../..//FileSelect.vue";
import axios from "@/api/axios-auth";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect },
  name: "CustomerVoicePostShow",
  data() {
    return {
      file: null,
      currentSignatureImagefile: null,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
      itemdata: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["getAdminId"]),
    getAnswerData: function () {
      function compare(a, b) {
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.itemdata.customer_voice_answers.sort(compare);
    },
    showAnswer: function () {
      if (
        this.itemdata.customer_voice_answers != null &&
        this.itemdata.customer_voice_answers.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    console.log("item ==> ", this.item);
    this.itemdata = this.item;

    // 시그니처 이미지 파일 정보요청
    this.$store
      .dispatch("req_signatureimage", {})
      .then((result) => {
        console.log("result =>", result.res.data);
        this.currentSignatureImagefile = result.res.data;
        this.file = {
          name: result.res.data.originalname,
        };
      })
      .catch((error) => {
        console.log("error =>", error.response);
        if (error.response.status == 404) {
          this.file = null;
        }
      });
  },
  methods: {
    onChangedSignatureImage(val) {
      console.log("onChangedSignatureImage ==> ", val);
      console.log("onChangedSignatureImage file ==> ", this.file);

      // 이때 파일 업로드를 해버린다.
      if (val != null) {
        let formData = new FormData();
        formData.append("title", "시그니처 이미지 파일");
        formData.append("signatureimage", this.file);

        axios
          .post("/admins/signatureimage", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log("res =>", res);
            this.$alert("업로드 성공").then(() => {
              this.currentSignatureImagefile = res.data;
            });
          })
          .catch((error) => {
            console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
            // console.log("error => ", error);
          });
      } else {
        // val 이 null 이면 삭제한다.
        // 삭제 요청 api  호출 필요
        axios
          .delete("/admins/signatureimage/1")
          .then((res) => {
            console.log("res =>", res);
            this.currentSignatureImagefile = res.data;
            this.$alert("삭제 성공").then(() => {});
          })
          .catch((error) => {
            console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
            // console.log("error => ", error);
            this.currentSignatureImagefile = null;
          });
      }
    },
    strReplaceReturnText(text) {
      let replacedText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
      return replacedText;
    },
    getCategoryName(category_id) {
      if (category_id == 1) return "칭찬 접수";
      else if (category_id == 2) return "불만 접수";
      else if (category_id == 3) return "단순 접수";
      else return "-";
    },
    onUpadateStatus(newstatus) {
      const payload = {
        id: this.itemdata.id,
        status: newstatus,
      };
      this.$store
        .dispatch("support/req_update_customervoice", payload)
        .then(() => {
          this.$alert("수정하였습니다.").then(() => {
            this.$router.push({ name: "CustomerVoicePostList" });
          });
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
    onSubmitDeleteOne() {
      console.log("onSubmitDeleteOne ==> ");

      this.$store
        .dispatch("support/req_remove_customervoice_list", {
          idarr: [this.itemdata.id],
        })
        .then((result) => {
          console.log("result => ", result);
          this.$router.push({ name: "CustomerVoicePostList" });
        });
    },

    //!----------------------------------  추가된 부분(답변등록 클릭 시)---------------------------------->
    onCustomerVoiceAnsewer() {
      if (this.editorData == null || this.editorData.length <= 0) {
        //
        return this.$alert("답변 내용을 입력해 주세요").then(() => {});
      }

      this.$fire({
        title: "답변을 등록하시겠습니까?",
        text: "답변등록시 고객에게 이메일이 발송되며, 수정 및 삭제가 불가합니다.",
        type: null,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      })
        .then((result) => {
          if (result.value) {
            // 등록완료
            const answerObj = {
              customer_voice_id: this.itemdata.id,
              customer_email: this.itemdata.email,
              customer_name: this.itemdata.name,
              customer_question: this.itemdata.question,
              respondent_id: this.getAdminId,
              answer: this.editorData,
            };

            // rest api call
            this.$store
              .dispatch("support/req_create_customervoice_answer", answerObj)
              .then(() => {
                // 답변 text delete
                this.$alert("답변 등록이 완료되었습니다.").then(() => {
                  this.editorData = "";
                });

                this.$store
                  .dispatch("support/req_customervoice_answer_list", {
                    customer_voice_id: this.itemdata.id,
                  })
                  .then((results) => {
                    this.itemdata.customer_voice_answers = results.data;
                    console.log("list => ", results);
                  });
              })
              .catch((error) => {
                // this.$alert("수정하였습니다.").then(() => {
                //   this.$router.push({ name: "CustomerVoicePostList" });
                // });
                console.log("error => ", error);
              });
          } else {
            //
            console.log("cancel 답변등록");
          }
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    //!----------------------------------  추가된 부분---------------------------------->
  },
};
</script>
<style lang="scss" scoped>
.customoer-voice-post-show-item:not(:first-child) {
  margin-top: 130px;
}
//고객의 소리 답변 히스토리
.customoer-voice-post-history {
  .col3 {
    width: 250px;
  }
  table {
    th {
      padding: 14px 15px;
    }
    td:nth-child(1),
    td:nth-child(3) {
      text-align: center;
    }
  }
}
</style>
